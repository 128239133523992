<div class="error-page">
  <div class="error-page__container">
    <div class="error-page__title">
      <ui-icon class="error-page__icon" icon="error" filled />
      <h2>{{ error?.name ? error?.name : 'Error' }}</h2>
    </div>
    <p>
      @if (error && error.message) {
        {{ error.message }}
      } @else {
        Something went wrong
      }
    </p>
  </div>
</div>
