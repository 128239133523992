import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ButtonComponent } from '@client-portal/ui/button';
import { IconComponent } from '@client-portal/ui/icon';

@Component({
  selector: 'clp-error',
  standalone: true,
  imports: [ButtonComponent, IconComponent],
  templateUrl: './error.component.html',
  styleUrls: [
    './error.component.scss',
    '../../../../shared/styles/error-page.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  @Input() public error: Error | undefined;
}
